<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    options() {
      this.updateSrc();
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    updateOptions: function () {
      var vm = this;
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          vm.updateSrc(false);
        }
      );
    },
    updateSrc: function () {
      if (this.player) {
        this.player.pause();
        this.player.src(this.options.src);
        this.player.load();
      }
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
