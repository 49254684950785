<template>
  <span v-html="getSVG" class="inline-svg"></span>
</template>
<script>
export default {
  name: "admininlinesvg",
  props: {
    src: { type: String, required: true },
  },
  computed: {
    // getSVG() {
    //     return __SVG[this.src];
    // },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/theme.less";
@import "../../less/media.less";
@import "../../less/text.less";
@import "../../less/color.less";
@import "../../less/page-header.less";
@import "../../less/main.less";
.inline-svg {
  .flex-center();
}
</style>
