var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_body"},[_c('ext-bridge',{on:{"hintChanged":_vm.hintChanged}}),_c('custom-breadcrumb',{staticClass:"breadcrumbs",attrs:{"navigations":_vm.navs}}),(_vm.showDialog)?_c('confirmation-dialog',{ref:"confirmation-dialog-ref",attrs:{"title":_vm.confirmationTitle,"description":_vm.confirmationMessage},on:{"close":_vm.closeConfirmationDialog,"proceed":_vm.saveStoreMapping}}):_vm._e(),_c('div',{class:['table-container']},[_c('div',{staticClass:"table-container-top"},[_vm._m(0),_c('div',{class:['pad-left-top', 'cancel-btn']},[_c('nitrozen-button',{directives:[{name:"strokeBtn",rawName:"v-strokeBtn"}],attrs:{"theme":"secondary"},on:{"click":_vm.cancelStoreMapping}},[_vm._v("Cancel ")])],1),_c('div',{class:['pad-left-top', 'save-btn']},[_c('nitrozen-button',{directives:[{name:"flatBtn",rawName:"v-flatBtn"}],class:{
            disabled:
              _vm.locationData.toggle_activation === false ||
              _vm.storeGstStatus(_vm.locationData) !== 'verified',
          },attrs:{"id":"save","theme":"secondary"},on:{"click":_vm.openConfirmationDialogBox}},[_vm._v("Save ")])],1)]),_c('div',{staticClass:"store-name"},[_vm._v(" "+_vm._s(_vm.locationData.name)+" ")]),_c('div',{staticClass:"store-address"},[_vm._v(" "+_vm._s(`${_vm.locationData.address.city} , ${_vm.locationData.address.state} , ${_vm.locationData.address.country}`)+" ")]),(_vm.locationData.toggle_activation === false)?_c('div',{staticClass:"preview-strip-error preview-strip-gst-error"},[_c('inline-svg',{staticClass:"feature-icon preview-error-icon",attrs:{"src":'warning'}}),_c('p',{staticClass:"preview-error-txt"},[_vm._v(" Location was updated recently. Please try again after some time. ")])],1):_vm._e(),(_vm.storeGstStatus(_vm.locationData) !== 'verified')?_c('div',{staticClass:"preview-strip-error preview-strip-gst-error"},[_c('inline-svg',{staticClass:"feature-icon preview-error-icon",attrs:{"src":'warning'}}),_c('p',{staticClass:"preview-error-txt"},[_vm._v(" GST verification is pending for this selling location. ")]),_c('nitrozen-button',{directives:[{name:"flatBtn",rawName:"v-flatBtn"}],staticClass:"preview-error-btn",attrs:{"theme":"secondary"},on:{"click":function($event){return _vm.verifyLocation(_vm.locationData.store_id || _vm.locationData.uid)}}},[_vm._v(" Get it verified now")])],1):_vm._e(),(!_vm.isEmpty(_vm.locationData))?[_c('div',{staticClass:"headers"},_vm._l((_vm.headers),function(header,index){return _c('div',{key:index,class:[
            'header',
            'regular-xsp',
            'cl-Mako',
            {
              'sync-col-width':
                header.title === 'Status' || header.title === 'Details',
            },
            {
              'location-code': header.title === 'Location Code',
            },
          ]},[_vm._v(" "+_vm._s(header.title)+" "),(header.tooltip)?_c('nitrozen-tooltip',{staticClass:"header-tooltip",attrs:{"position":"top"}},[_vm._v(" "+_vm._s(header.tooltip)+" ")]):_vm._e()],1)}),0),_c('div',{staticClass:"row-container"},[_c('div',{class:['row', { 'row-flex': _vm.isMappingError }]},_vm._l((_vm.headers),function(header,index){return _c('div',{key:index,class:[
              'row-value',
              'cl-Mako',
              {
                'sync-col-width':
                  header.title === 'Status' || header.title === 'Details',
              },
              {
                'location-code': header.title === 'Location Code',
              },
              {
                'input-mkp-store-id':
                  _vm.locationData.error != '' &&
                  header.key == 'marketplace_store_id',
              },
              /*{
                'input-error':
                  header.key == 'marketplace_store_id' && locationData.error,
              },*/
            ]},[(header.type === 'text')?[(_vm.isHintActive)?_c('nitrozen-hint',{attrs:{"steps":_vm.hintSteps,"hintType":"loc_hint_status"},on:{"hintInactive":_vm.setHintInactive}}):_vm._e(),_c('div',{staticClass:"text-overflow regular-xs",attrs:{"id":header.key}},[_vm._v(" "+_vm._s(header.obj ? _vm.getValue(_vm.locationData, `${header.obj}.${header.key}`) || "-" : _vm.getValue(_vm.locationData, header.key) || "-")+" ")]),(header.key === 'name')?_c('nitrozen-tooltip',{staticClass:"tooltip"},[_c('div',{staticClass:"edit-icon",attrs:{"id":header.key},on:{"click":function($event){return _vm.goToStore(_vm.locationData.uid)}}},[_c('adm-inline-svg',{attrs:{"src":"edit"}})],1),(_vm.locationData.address && _vm.locationData.address.address1)?_c('div',{staticClass:"regular-xxs"},[_vm._v(" Address: "+_vm._s(_vm.locationData.address.address1)+" ")]):_vm._e(),_c('div',{staticClass:"regular-xxs"},[_vm._v(" City: "+_vm._s(_vm.locationData.address.city)+" ")]),_c('div',{staticClass:"regular-xxs"},[_vm._v(" State: "+_vm._s(_vm.locationData.address.state)+" ")]),_c('div',{staticClass:"regular-xxs"},[_vm._v(" Pincode: "+_vm._s(_vm.locationData.address.pincode)+" ")])]):_vm._e()]:_vm._e(),(header.type === 'input')?[(header.keyType === 'number')?_c('div',{class:['handle'],attrs:{"id":header.key}},[(header.obj)?_c('nitrozen-input',{class:[
                    'regular-xs',
                    'number-width',
                    {
                      disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                    },
                  ],attrs:{"type":"number"},model:{value:(_vm.locationData[header.obj][header.key]),callback:function ($$v) {_vm.$set(_vm.locationData[header.obj], header.key, $$v)},expression:"locationData[header.obj][header.key]"}}):_c('nitrozen-input',{class:[
                    'regular-xs',
                    'number-width',
                    {
                      disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                    },
                  ],attrs:{"min":0,"type":_vm.number},nativeOn:{"keydown":function($event){return _vm.checkNumberInput.apply(null, arguments)}},model:{value:(_vm.locationData[header.key]),callback:function ($$v) {_vm.$set(_vm.locationData, header.key, $$v)},expression:"locationData[header.key]"}})],1):_c('div',{class:['handle'],attrs:{"id":header.key}},[(header.obj)?_c('nitrozen-input',{class:[
                    'regular-xs',
                    {
                      disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                    },
                  ],attrs:{"type":"text"},model:{value:(_vm.locationData[header.obj][header.key]),callback:function ($$v) {_vm.$set(_vm.locationData[header.obj], header.key, $$v)},expression:"locationData[header.obj][header.key]"}}):_c('nitrozen-input',{class:[
                    'regular-xs',
                    {
                      disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                    },
                  ],attrs:{"type":"text"},model:{value:(_vm.locationData[header.key]),callback:function ($$v) {_vm.$set(_vm.locationData, header.key, $$v)},expression:"locationData[header.key]"}})],1),_c('div',{staticClass:"store-error"},[(
                    header.key == 'marketplace_store_id' && _vm.locationData.error
                  )?_c('nitrozen-error',[_vm._v(_vm._s(_vm.locationData.error)+" ")]):_vm._e()],1)]:_vm._e(),(header.type === 'switch')?[_c('div',{class:[
                  _vm.storeGstStatus(_vm.locationData) !== 'verified'
                    ? 'cursor-disabled'
                    : '',
                ],attrs:{"title":_vm.storeGstStatus(_vm.locationData) != 'verified'
                    ? 'GST is not verified'
                    : '',"id":"enabled"}},[_c('nitrozen-toggle',{class:{
                    disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                  },model:{value:(_vm.locationData[header.key]),callback:function ($$v) {_vm.$set(_vm.locationData, header.key, $$v)},expression:"locationData[header.key]"}})],1)]:_vm._e(),(header.type === 'badge')?[_c('div',{class:_vm.storeGstStatus(_vm.locationData) !== 'verified'
                    ? 'cursor-disabled'
                    : '',attrs:{"title":_vm.storeGstStatus(_vm.locationData) != 'verified'
                    ? 'GST is not verified'
                    : ''}},[_c('nitrozen-badge',{class:{
                    disabled: _vm.storeGstStatus(_vm.locationData) !== 'verified',
                  },attrs:{"state":_vm.locationData[header.key] ? 'success' : 'error'}},[_vm._v(_vm._s(_vm.locationData[header.key] ? "ACTIVE" : "INACTIVE")+" ")])],1)]:_vm._e(),(header.type === 'switch')?void 0:_vm._e()],2)}),0)])]:_vm._e()],2),(_vm.inProgress)?_c('loader',{staticClass:"loading",attrs:{"helperText":_vm.loaderHelperText}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selling-location-block"},[_c('p',{staticClass:"selling-location-txt"},[_vm._v("Edit Selling Locations")]),_c('p',{staticClass:"selling-location-desc"},[_vm._v(" Enter Myntra PPMP location code to map and enable to make inventory flow through this location ")])])
}]

export { render, staticRenderFns }