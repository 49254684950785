
<script>
import EXT from "../extension_bridge";
import { components } from "@gofynd/fdk-extension-bridge-javascript";
import loader from "@/components/common/adm-loader"; // eslint-disable-line no-unused-vars
import { setCompany } from "@/helper/utils";
import marketplaceSettings from "./marketplace-setting-form.vue";
import menu from "./menu.vue";
import admJumbotron from "@/components/common/adm-jumbotron.vue";

export default {
  name: "main",
  components: {
    loader,
    "adm-jumbotron": admJumbotron,
    "marketplace-settings": marketplaceSettings,
    "menu-items": menu,
  },
  props: {
    isHintAdded: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let contextMenuItm = new components.ContextMenuItem(EXT, {
      label: "Settings",
    });
    let unsubcribe_handler /* eslint-disable-line no-unused-vars*/ =
      contextMenuItm.subscribe("click", async () => {
        try {
          this.$router
            .push({
              name: "settings",
              params: {
                showSettings: true,
              },
            })
            .catch((err) => console.log("error", err));
        } catch (error) {
          console.log("error is", error);
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        }
      });
    contextMenuItm.dispatch();
    if (this.isHintAdded) {
      let hintContextMenuItm = new components.ContextMenuItem(EXT, {
        label: "Hints",
      });
      let unsubcribe_handler2 /* eslint-disable-line no-unused-vars*/ =
        hintContextMenuItm.subscribe("click", async () => {
          this.$emit("hintChanged");
        });
      hintContextMenuItm.dispatch();
    }
  },
  methods: {},
  beforeRouteEnter: (to, from, next) => {
    console.log("to", to);
    console.log("from", from);
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
  destroyed() {
    EXT.destroy();
  },
};
</script> 

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
</style>
